import { useState } from 'react';

import '../styles/home.scss';
import '../styles/color-variations.scss';

const possibleVariations = [
	'v00', 'v01', 'v02', 'v03', 'v04', 'v05', 
	'v06', 'v07', 'v08', 'v09', 'v10', 'v11', 
	'v12', 
];

function getRandomVariation() {
	return possibleVariations[Math.round(Math.random() * (possibleVariations.length - 1))];
}

function Home() {
	const queryParameters = new URLSearchParams(window.location.search)
	const queryVariation = queryParameters.get("var");

	const [variation, setVariation] = useState(
		possibleVariations.find(v => v === queryVariation) ? 
						queryVariation : getRandomVariation()
	);

  	return (
		<div id="home" className={variation}>
			<img id='kockice' src='/images/kockice.svg' alt='Randomiziraj' onClick={() => setVariation(getRandomVariation())} />

			<img id='logo' src={`/images/logo-variations/logo-${variation}.png`} alt="Studio 31" />

			<a href='https://wa.me/385958645534' target='_blank'>
				<span className="primary-color">Pošalji WhatsApp poruku</span>
			</a>
			<a href='tel:385958645534' target='_blank'>
				<span className="primary-color">Zovi: +385 95 864 5534</span>
			</a>
			<a href='mailto:info@studio31.hr' target='_blank'>
				<span className="primary-color">Piši: info@studio31.hr</span>
			</a>
		</div>
  	);
}

export default Home;
